import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API } from 'services';

export const useLoginState = () => {
  const queryClient = useQueryClient();
  const { data, error = false } = useQuery('isLogin', () =>
    API.get('/master_account')
  );

  const loginUserGetOTP = (phone_number) =>
    API.post('/master_account/otp', { phone_number });

  const loginUserSignIn = useMutation(
    (otp) => API.post('/master_accounts/sign_in', { otp }),
    { onSuccess: () => queryClient.invalidateQueries('isLogin') }
  );

  const logoutUserMutation = useMutation(
    () => API.delete('//master_accounts/sign_out'),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('isLogin');
        document.location.reload()
      }
    }
  );

  return [
    { user: data, isLogin: error ? false : true },
    { loginUserGetOTP, loginUserSignIn, logoutUserMutation }
  ];
};
