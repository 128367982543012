import { useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import { BasicLayout } from 'components/layouts/BasicLayout';
import { useSlaves } from 'hooks/useSlaves';
import { useHistory } from 'react-router-dom';
import {
  FormGroup,
  FormControlLabel,
  Switch,
  TextField,
  Grid,
  Button
} from '@material-ui/core';
import { API } from 'services';

export const Collectibles = (props) => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({ s: 'created_at desc' });
  const [search, setSearch] = useState();
  const [{ isLoading, data: response }] = useSlaves({ page, filter, search });

  const handleSortChange = (sortModel) => {
    const order = sortModel[0]?.sort || 'desc';
    setFilter({ ...filter, s: `created_at ${order}` });
  };

  const handleSearch = (event) => {
    if (event.key === 'Enter') {
      setSearch(event.target.value);
      setPage(1);
    }
  };
  const classes = useStyles();

  return (
    <BasicLayout>
      {/* <FormGroup>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <FormControlLabel label="Only banned"  control={
                    <Switch color="primary" onChange={handleOnlyBannedSwitchChange} />
                  }/>
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel label="Have abuse"  control={
                    <Switch color="primary" onChange={handleOnlyAbusedSwitchChange} />
                  }/>
                </Grid>
                <Grid item xs={6}></Grid>
              </Grid>
            </FormGroup> */}
      <TextField
        fullWidth
        label="Search"
        variant="outlined"
        margin="dense"
        onKeyPress={handleSearch}
      />
      <div style={{ height: '80%', minHeight: '600px' }}>
        <DataGrid
          loading={isLoading}
          className={classes.root}
          columns={columns}
          rows={response?.users || []}
          pageSize={20}
          page={page - 1}
          rowCount={response?.pagy.count}
          disableSelectionOnClick
          disableColumnMenu
          onPageChange={(page) => setPage(page + 1)}
          onSortModelChange={handleSortChange}
          sortingMode="server"
          paginationMode="server"
          getRowClassName={(params) =>
            `collectible-row-theme--${
              params.getValue(params.id, 'abuses_presence') ? 'Abused' : 'Open'
            }`
          }
        />
      </div>
    </BasicLayout>
  );
};
const imageStyle = {
  width: '100%',
  padding: '3px',
  height: '100%',
  objectFit: 'cover'
};

const ImageCell = (params) => (
  <img src={params.value} alt="" style={imageStyle} />
);
// const BannedCell = (params) => {
//     return <Switch disabled checked={params.value}/>;
// }
const SignInCell = (params) => (
  <Button
    variant="contained"
    color="primary"
    onClick={() => {
      API.post(`/master_account/users/${params.value}/session`).then(() => window.open(`${window.location.origin}`, '_blank'));
    }}
  >
    Sign-in
  </Button>
);
const DateCell = (params) => new Date(params.value).toLocaleDateString();

const columns = [
  {
    field: 'id',
    headerName: 'SignIn',
    width: 75,
    renderCell: SignInCell,
    editable: false,
    filterable: true,
    sortable: false
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 120,
    // renderCell: ImageCell,
    sortable: false,
    editable: false,
    filterable: false
  },
  {
    field: 'phone_number',
    headerName: 'Phone number',
    width: 250,
    sortable: false,
    filterable: false
  },
  {
    field: 'creation_permission',
    headerName: 'Permission',
    width: 100,
    sortable: false,
    filterable: false
  },
  {
    field: 'easynft_address',
    headerName: 'Tx',
    width: 200,
    sortable: false,
    filterable: false
  },
  {
    field: 'email',
    headerName: 'email',
    width: 100,
    sortable: false,
    filterable: false
  },
  {
    field: 'created_at',
    headerName: 'Created',
    renderCell: DateCell,
    width: 110
  }
];

const useStyles = makeStyles((theme) => {
  return {
    root: {
      '& .collectible-row-theme--Abused': {
        backgroundColor: '#F44336',
        '&:hover': {
          backgroundColor: '#E57373'
        }
      }
    }
  };
}, {});
