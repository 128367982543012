import {
  Grid,
  Box,
  Button,
  Container,
  Icon,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  makeStyles
} from '@material-ui/core';
import { Logo } from 'components/common/Logo';
import { Link } from 'react-router-dom';
import { useLoginState } from 'hooks/useLoginState';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { API } from 'services';
import { useSlaves } from 'hooks/useSlaves';
import { useState } from 'react';

export const BasicLayout = ({ children }) => {
  const classes = useStyles();
  const [createModal, setCreateModal] = useState(false);

  const [, { logoutUserMutation }] = useLoginState();

  return (
    <Container maxWidth="md" style={{ paddingBottom: '30px' }}>
      <Box px={2} py={1} my={5}>
        <Grid container component="header" justifyContent="space-between">
          <Grid
            item
            xs={12}
            justifyContent="center"
            alignContent="center"
            container
            sm={2}
          >
            <Link to="/">
              <Logo />
            </Link>
          </Grid>
          <Grid
            item
            container
            sm={10}
            spacing={1}
            classes={{
              root: classes.headerControls
            }}
          >
            <Grid item>
              <Button color="primary" onClick={() => setCreateModal(true)}>
                <span>Add user</span>
                <Icon className={classes.marginLeft}>add</Icon>
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                onClick={() => logoutUserMutation.mutate()}
              >
                <span>Log out</span>
                <Icon className={classes.marginLeft}>logout</Icon>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <CreateUserModal
        handleClose={() => setCreateModal(false)}
        open={createModal}
      />
      {children}
    </Container>
  );
};

const CreateUserModal = ({ handleClose, open }) => {
  const queryClient = useQueryClient();
  const { register, handleSubmit } = useForm();
  const handleCreateUser = async (form) => {
    await API.post('/master_account/users', form);
    queryClient.invalidateQueries('slaves');
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Create Slave account'}
      </DialogTitle>
      <form onSubmit={handleSubmit(handleCreateUser)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={12}>
              <TextField
                fullWidth
                required
                label="Name"
                placeholder="Name"
                variant="outlined"
                {...register('name')}
              />
            </Grid>
            <Grid item xs={6} md={12}>
              <TextField
                fullWidth
                required
                label="Description"
                placeholder="Description"
                variant="outlined"
                {...register('description')}
              />
            </Grid>
            <Grid item xs={6} md={12}>
              <TextField
                fullWidth
                required
                label="E-mail"
                placeholder="E-mail"
                variant="outlined"
                {...register('email')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1)
  },
  marginRight: {
    marginRight: theme.spacing(1)
  },
  marginLeft: {
    marginLeft: theme.spacing(1)
  },
  headerControls: {
    margin: theme.spacing(2, 0, 2, 0),
    justifyContent: 'flex-end',
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'center',
      '& button>span>span+span': {
        display: 'none'
      },
      '& button>span>span': {
        margin: 0
      }
    }
  }
}));
