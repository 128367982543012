import { useState } from 'react';
import { Box, Button, Grid, makeStyles, TextField } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { Logo } from 'components/common/Logo';
import { useLoginState } from 'hooks/useLoginState';
import { useForm } from 'react-hook-form';
import { Redirect, useHistory } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '& .MuiInputBase-root': {
      marginBottom: theme.spacing(3)
    },
    '& h2': {
      margin: theme.spacing(6),
      textTransform: 'uppercase'
    }
  },
  button: {
    width: '60%',
    margin: '0 auto'
  }
}));

export const Login = (props) => {
  const [{ isLogin }, { loginUserSignIn, loginUserGetOTP }] = useLoginState();
  const [isOTP, setIsOTP] = useState(false);
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const classes = useStyles();

  const handleLogin = async (form) => {
    if (isOTP) {
      await loginUserSignIn.mutateAsync(form.otp);
      history.push('/');
    } else {
      await loginUserGetOTP(form.phone_number);
      setIsOTP(true);
    }
  };

  if (isLogin) return <Redirect to="/" />;
  return (
    <Container>
      <Box
        className={classes.wrapper}
        display="flex"
        flexDirection="column"
        sm={12}
        alignItems="center"
      >
        <Logo />
        <h2>Sign in</h2>
        <form onSubmit={handleSubmit(handleLogin)}>
          <Grid container spacing={2}>
            {!isOTP ? (
              <TextField
                key="number"
                fullWidth
                required
                label="Phone number"
                placeholder="+79998883322"
                variant="outlined"
                {...register('phone_number')}
              />
            ) : (
              <TextField
                key="OTP"
                fullWidth
                required
                label="Code"
                placeholder="Code"
                variant="outlined"
                {...register('otp')}
              />
            )}
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              className={classes.button}
            >
              Sign in
            </Button>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};
