import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API } from 'services';
import qs from 'qs';

export const useSlaves = (opts) => {
  const queryClient = useQueryClient();
  const stUrl = qs.stringify(
    { page: opts.page, q: opts.filter, search: opts.search },
    { encode: false }
  );
  const apiURL = `/master_account/users?${stUrl}`;
  const query = useQuery([`slaves`, stUrl], () => API.get(apiURL));

  const createSlave = useMutation(
    'slaves',
    (form) => {
      API.post('/master_account/users', form);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`slaves`);
      }
    }
  );
  return [{ ...query }, createSlave];
};
export const useSingleCollectible = (collectibleId) => {
  const queryClient = useQueryClient();

  const query = useQuery([`collectibles`, collectibleId], () =>
    API.get(`/admin/collectibles/${collectibleId}`)
  );

  const updateCollectibleMutation = useMutation(
    [`/admin/collectibles/`, collectibleId],
    (data) => API.patch(`/admin/collectibles/${collectibleId}`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`collectibles`);
      }
    }
  );

  return { ...query, updateCollectibleMutation };
};

export const useCollectibleAbuses = (collectibleId) => {
  const query = useQuery([`/admin/collectibles/abuses`, collectibleId], () =>
    API.get(`/admin/collectibles/${collectibleId}/abuses`)
  );

  return { ...query };
};
